window.addEventListener('DOMContentLoaded', () => {
    const addMessageToChat = (content, type) => {
            let message = document.querySelector('.js-chatbot-message-model').cloneNode(true);

            if (type === 'assistant') {
                message.classList.add('chatbot-response');
            } else {
                message.querySelector('.avatar-container').remove();
                message.classList.add('chatbot-question');
            }

            message.classList.remove('js-chatbot-message-model');
            message.classList.remove('d-none');
            message.querySelector('.chatbot-message-content').innerText = content;

            messagesContainer.appendChild(message)
            textarea.value = '';

            scrollToLastMessage();
        };

    const addIsWritingMessage = () => {
        let message = document.querySelector('.js-chatbot-message-model').cloneNode(true);

        message.classList.add('js-chatbot-wrinting-message');
        message.classList.add('chatbot-response');
        message.classList.remove('d-none');
        message.classList.remove('js-chatbot-message-model');
        message.querySelector('.chatbot-message-content').innerText = 'Est en train d\'écrire...';

        messagesContainer.appendChild(message)

        scrollToLastMessage();
    };

    const removeIsWritingMessage = () => {
        document.querySelector('.js-chatbot-wrinting-message').remove();
    };

    const scrollToLastMessage = () => {
        let lastMessage = messagesContainer.lastChild;
        if (lastMessage) {
            lastMessage.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const sendMessageToChatbot = (chatbotId, message, threadId = null) => {
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                chatbotId,
                message,
                threadId
            })
        };

        return fetch(textarea.dataset.createMessageEndpoint, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la requête : ' + response.statusText);
                }

                return response.json();
            })
            .then(data => {
                return data;
            })
        ;
    };

    const getResponseFromChatbot = (threadId, attempt) => {
        attempt++;

        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        fetch(textarea.dataset.getResponseEndpoint.replace('%s', threadId), options)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur de réseau : ' + response.statusText);
                }

                return response.json();
            })
            .then(data => {
                if (data.response) {
                    removeIsWritingMessage();
                    return addMessageToChat(data.response, 'assistant');
                }

                if (attempt <= maxGetResponseAttempt) {
                    getResponseFromChatbot(threadId, attempt);
                }
            })
            .catch(error => {
                console.error('Erreur lors de la requête à l\'API :', error);

                if (attempt <= maxGetResponseAttempt) {
                    getResponseFromChatbot(threadId, attempt);
                }
            })
        ;
    }

    const getMessagesFromThread = (threadId) => {
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        fetch(textarea.dataset.getMessagesEndpoint.replace('%s', threadId), options)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur de réseau : ' + response.statusText);
                }

                return response.json();
            })
            .then(data => {
                data.conversation.forEach(message => {
                    return addMessageToChat(message.content, message.type);
                })
            })
            .catch(error => {
                console.error('Erreur lors de la requête à l\'API :', error);
            })
        ;
    }

    const userAddMessage = () => {
        let chatbotId = textarea.dataset.chatbotId ? textarea.dataset.chatbotId : 1;
        let message = textarea.value;
        let getResponseAttempt = 0;

        addMessageToChat(message, 'user');
        sendMessageToChatbot(chatbotId, message, threadId)
            .then(response => {
                if (!threadId) {
                    threadId = response.threadId;
                    localStorage.setItem('threadId', threadId);
                }

                addIsWritingMessage();
                getResponseFromChatbot(response.threadId, getResponseAttempt);
            })
            .catch(error => {
                console.error('Erreur lors de la requête à l\'API :', error);
                addMessageToChat('Une erreur est survenue', 'assistant')
            })
        ;
    };


    const chatbotDisplayButton = document.querySelector('.js-chatbot-display-button');
    const chatbotHideButton = document.querySelector('.js-chatbot-hide-button');
    const chatbotContainer = document.querySelector('.js-chatbot-container');
    const chatbotSpeechBubbleHideButton = document.querySelector('.js-chatbot-speech-bubble-hide-button');
    const messagesContainer = document.querySelector('.messages-container');
    const sendMessageButton = document.querySelector('.js-chatbot-send-message-button');
    const textarea = document.querySelector('.js-chatbot-textarea');
    const maxGetResponseAttempt = 60;
    let threadId = localStorage.getItem('threadId');

    if (threadId) {
        getMessagesFromThread(threadId);
    }

    sendMessageButton.addEventListener('click', () => {
        userAddMessage();
    });

    textarea.addEventListener('keydown', (e) => {
        if (event.keyCode === 13) {
            e.preventDefault();
            userAddMessage();
        }
    });

    chatbotDisplayButton.addEventListener('click', () => {
        chatbotContainer.classList.remove('d-none');
        chatbotDisplayButton.classList.add('d-none');
        scrollToLastMessage();
    });

    chatbotHideButton.addEventListener('click', () => {
        chatbotContainer.classList.add('d-none');
        chatbotDisplayButton.classList.remove('d-none');
    });

    if (chatbotSpeechBubbleHideButton) {
        chatbotSpeechBubbleHideButton.addEventListener('click', e => {
            e.stopPropagation();
            chatbotDisplayButton.remove();
        })
    }
});
